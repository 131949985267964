@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');


html{
    background: #f5f5f5;
    height: 100%;
}
body {
    overflow: hidden;
    height: calc(100% - 78px);
    margin:0;
    font-family: 'Noto Sans KR', sans-serif;
}
img{
    max-width: 100%;
}
header{
    height: 78px;
}
button{
    border: 0;
    outline: 0;
    background-color: transparent;
}
button:hover{
    cursor: pointer;
}
h1{
    position: absolute;
    display: contents;
}
.logo{
    position: absolute;
    display: inline-block;
    top: 15px;
    height: 48px;
    left: calc(50% - 24px);
    z-index: 3;
}
.closebtn{
    position: absolute;
    display: block;
    margin: 25px;
    padding: 3px;
    right: 0;
    font-size: large;
    color: white;
    display: none;
}
.infotext{
    position: absolute;
    display: none;
    color: white;
}
.infotext p{
    font-size: 16px;
}
.infotext a{
    color: white;
}
.contenthide{
    height: 100%;
    width: 100%;
    position: relative;
}
.questionbtn{
    position: absolute;
    display: inline-block;
    margin: 25px;
    padding: 3px;
    right: 0;
    font-size: large;
    color: black;
}
.contentbox {
    height: calc(100% - 45px);
    max-width: 100%;
    text-align: center;
    overflow: hidden;
    margin-bottom: 10px;
}
.work::-webkit-scrollbar{
    display: none;
}
.content {
    height: 100%;
    width: 80%;
    min-height: 400px;
    max-width: 88vh;
    display: inline-block;
    text-align: left;
}

.wrap-post{
    height: 100%;
    position: relative;
}
.info{
    float: left;
    width: 28%;
    height: 100%;
}
ul{
    list-style: none;
    padding: 0;
    display: inline-block;
}
.work{
    height: 100%;
    float: right;
    width: 70%;
    overflow-y: scroll;
}
.work > ul > li {
    margin-bottom: 8px;
}
.artists{
    width: 100%;
}
.artist{
    margin-right: 4px;
    display: inline;
}
a{
    text-decoration: none;
    color: rgb(31, 31, 31);
}
a:hover{
    cursor: pointer;
    color: rgb(170, 170, 170);
}
.artist-icon-wrap{
    display: block;
}
.artist-icon i{
    font-size: 18px;
    margin-right: 8px;
}
h2.infoname{
    font-size: 16px;
    margin-bottom: 0px;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
}
.p {
    -webkit-line-clamp: 16;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 15px;
    padding-top: 5px;
    margin-bottom: 0px;
    width: calc(100% - 16px);
    background-color: whitesmoke;
    overflow: hidden;
}
.morebtn {
    display: none;
    position: relative;
    left: -2px;
    top: -18px;
    float: right;
    text-align: center;
    width: 25px;
    color: black;
}
@media (max-width: 768px) {
    .content {
        width: 96%;
    }
    .work{
        width: 65%;
    }
    .info{
        width: 31%;
    }
}

.footer{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 15px;
    text-align: center;
    font-size: small;
    z-index: 4;
}
.footer p {
    margin: 0;
    color: #323232;
}
.footer p > a {
    color: #525252;
    text-decoration: none;
}
@media (prefers-color-scheme: dark) {
    html{
      background: rgb(15, 15, 15);
      color: white;
    }
    .closebtn{
        color: black;
    }
    .infotext{
        color: black;
    }
    .infotext p{
        font-size: 16px;
    }
    .p {
        background-color: rgb(15, 15, 15);
    }
    .infotext a{
        color: black;
    }
    .morebtn{
        color: white;
    }
    .questionbtn{
        color: white;
    }
    a{
        text-decoration: none;
        color: rgb(240, 240, 240);
    }
    a:hover{
        color: rgb(175, 175, 175);
    }
    .footer p {
        color: rgb(226, 226, 226);
    }
    .footer p > a {
        color: white;
    }
  }